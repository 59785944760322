import { useSlug } from 'hooks/useSlug';
import TemplateMapper from 'templates/TemplateMapper';

import { Form } from 'components/Forms';
import type { Form as TForm } from 'server/repository/hygraph/generated/graphqlTypes';
import type { AvailableTemplates, ISharedTemplateProps } from 'templates/TemplateMapper/types';
interface IFormContainer extends ISharedTemplateProps {
  templateProps: TForm;
}

export const FormContainer = ({
  templateProps,
  copy,
  pageType,
  formSubmitCallback = () => {},
}: IFormContainer) => {
  const { formType } = templateProps;
  const slug = useSlug();

  const successComponents = (
    <TemplateMapper
      components={(templateProps?.formSuccess?.components as AvailableTemplates[]) ?? []}
      copy={copy}
    />
  );

  return (
    <>
      <Form
        withCapture={formType === 'newsletter'}
        formData={templateProps}
        formType={formType as 'newsletter' | 'promotion' | 'contact'}
        successComponents={successComponents}
        disableScrollOnSubmit={!!(pageType === 'promotion')}
        formSubmitCallback={formSubmitCallback}
        slug={slug ?? ''}
      />
    </>
  );
};
